import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { calculateSeatPosition, useGameStore } from '@/entities/game'
import { PlayerCard } from '@/entities/game/ui/PlayerCard/PlayerCard'

import { useImageError } from '@/shared/hooks'
import { Chip } from '@/shared/ui'
import { formatNumber } from '@/shared/utils'
import { capitalize } from '@/shared/utils/capitalize'

import { PlayerAtTable } from '../../model/user.types'

import c from './UserInGame.module.scss'

type UserInGameProps = {
	index: number
	seat: number
} & PlayerAtTable

export function UserInGame(user: UserInGameProps) {
	const { t } = useTranslation()
	const isHandFlipped = [3, 4].includes(user.index)
	const [isAvatarErr, onAvatarErr] = useImageError()

	const { game, playerSeat } = useGameStore()
	const isCurrentTurn = game.current_turn === user.id

	// const isDealer = game.current_dealer === user.index + 1
	const isSB = game.small_blind_position === user.index + 1
	const isBB = game.small_blind_position === user.index

	const isPlayer = playerSeat === user.index + 1

	const pos = calculateSeatPosition(user.seat, 6, 40, -4)

	const lastAction = user.last_action ? capitalize(user.last_action) : null

	return (
		<div
			className={cn(
				c['user-in-game'],
				isPlayer && c['player'],
				!user.active_player && c['--inactive']
			)}
			data-game-seat={user.seat}
			data-seat-index={user.index}
			style={pos}>
			<div className={c['user-in-game__data']}>
				<div className={cn(c['user-in-game__data--avatar'], isCurrentTurn && c['--current-turn'])}>
					{isAvatarErr && <span className={c['user-in-game__data--avatar-plug']} />}
					{!isAvatarErr && <img src={'null'} width={36} height={36} onError={onAvatarErr} />}
				</div>
				<span className='user-in-game__data--username'>{lastAction ?? user.username}</span>
				<span className={c['user-in-game__data--bank']}>
					{user.ready ? `${user.player_balance}$` : t('waiting')}
				</span>
			</div>

			<div className={c['user-in-game__hand']}>
				{user.hand.map(c =>
					c ? (
						<PlayerCard
							key={`card-${c}`}
							isPlayer={isPlayer}
							isFlipped={isHandFlipped}
							codeName={c}
						/>
					) : null
				)}
			</div>

			<span className={c['user-in-game__blind']}>
				{isSB && <span>SB</span>}
				{isBB && <span>BB</span>}
			</span>
			{Number(user.last_bet) > 0 && (
				<span className={c['user-in-game__bet']}>
					{formatNumber(Number(user.last_bet))} <Chip />
				</span>
			)}
			{/* {user.combo && (
				<div className={c['user-in-game__combo']}>
					<span>{user.combo}</span>
				</div>
			)} */}
		</div>
	)
}
