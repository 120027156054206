import { useOverlay } from '@/components/Overlay'

import { Icon } from '@/shared/ui'

import { useGameActions } from '../..'
import { calculateSeatPosition } from '../../lib/game.utils'
import { useGameStore } from '../../model/game.store'
import type { GameWS } from '../../model/game.types'

import c from './GameSeat.module.scss'

export type GameSeatProps = {
	index: number
	seat: number
}

export function GameSeat({ seat, index }: GameSeatProps) {
	const ws = useGameStore(s => s.ws)
	if (!ws) throw new Error('Game WebSocket is null')
	const playerSeat = useGameStore(s => s.playerSeat)
	const isPlayerSeat = !(playerSeat === null)

	const { gameSetPlayerSeat } = useGameActions()
	const pos = calculateSeatPosition(seat, 6, 40, 12)

	const [, toggleOverlay] = useOverlay('buy-in-overlay')

	const onTakeSeat = async () => {
		await ws.send<GameWS['sit']>({ type: 'sit', chair_number: seat })
		toggleOverlay(true)
		gameSetPlayerSeat(seat)
	}

	const onInvite = () => console.log('invite')

	const handleClick = () => {
		if (isPlayerSeat) onInvite()
		else onTakeSeat()
	}

	return (
		<div
			className={c['game-seat']}
			data-game-seat={seat}
			data-seat-index={index}
			onClick={handleClick}
			style={pos}>
			{isPlayerSeat ? (
				<span className={c['game-seat__icon']}>+</span>
			) : (
				<Icon name='arrow-down' w={26} />
			)}
		</div>
	)
}
