import classNames from 'classnames'

import c from './Icon.module.scss'

interface IconProps extends Omit<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'className'> {
	name: string
	w?: number
	h?: number
	className?: string
}
/**
 * Компонент для отображения иконок из SVG спрайта.
 *
 * @param {string} name - Название иконки, соответствующее ID символа в SVG спрайте.
 * @param {number} w - Ширина иконки в пикселях.
 * @param {number} h - Высота иконки в пикселях. Если не указана, используется значение ширины.
 * @param {string} className - Дополнительные CSS классы для стилизации иконки.
 *
 * @example
 * ```tsx
 * <Icon name="home" w={32} h={32} className="custom-class" />
 * ```
 */
export function Icon({ name, className = '', w = 18, h, ...svgProps }: IconProps) {
	return (
		<svg
			className={classNames(c.icon, className)}
			width={w}
			height={h ?? w}
			id={name}
			{...svgProps}>
			<use href={`#icon-${name}`} />
		</svg>
	)
}
