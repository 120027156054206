import { useGetCardSprite } from '@/entities/files'

import { Icon } from '@/shared/ui'

import { Card } from '../../model/game.types'

import c from './PlayingCard.module.scss'

export function PlayingCard({ codeName }: Card) {
	const spriteURL = useGetCardSprite(codeName)

	return (
		<div className={c['card']}>
			{codeName === 'PLUG' && (
				<div className={c['card--facedown']}>
					<Icon name='card-shirt-icon' className={c['card--facedown-icon']} />
				</div>
			)}
			{codeName !== 'PLUG' && <img width={70} height={100} src={spriteURL} />}
		</div>
	)
}
