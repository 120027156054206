import cn from 'classnames'
import { useEffect } from 'react'

import { useGameStore } from '@/entities/game'

import { type ChatMessageLog, type ChatMessageUser, useChatActions } from '../..'
import { useChatStore } from '../../model/chat.store'
import { ChatMessage } from '../ChatMessage/ChatMessage'

import c from './ChatWindow.module.scss'

export function ChatWindow() {
	const ws = useGameStore(s => s.ws)
	if (!ws) throw new Error('Game WebSocket is null')

	const { chatAddMessage } = useChatActions()

	useEffect(() => {
		ws.on<ChatMessageUser>('chat_message', chatAddMessage)
		ws.on<ChatMessageLog>('chat_log', chatAddMessage)
	}, [ws, chatAddMessage])

	const selectedChat = useChatStore(s => s.selectedChat)
	const logs = Array.from(useChatStore(s => s.logs))
	const msg = Array.from(useChatStore(s => s.msgs))

	return (
		<div className={cn(c['chat-window'], c[selectedChat])}>
			<div className={c['chat-window--users']}>
				{msg.map((m, i) => (
					<ChatMessage key={`${m.id}-${m.type}-${i}`} {...m} />
				))}
			</div>
			<div className={c['chat-window--dealer']}>
				{logs.map((m, i) => (
					<ChatMessage key={`${m.id}-${m.type}-${i}`} {...m} />
				))}
			</div>
		</div>
	)
}
