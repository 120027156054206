import { create } from 'zustand'

import { createStoreManager } from '@/shared/utils'

import { gameDynamicsInit } from './game.init'
import type { GameStore } from './game.types'

export const useGameStore = create<GameStore>(() => ({
	ws: null,
	stage: 'init',
	playerSeat: null,

	game: gameDynamicsInit
}))

export const gameStoreManager = createStoreManager(useGameStore)
