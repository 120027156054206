import { useState } from 'react'

import { useGameStore } from '../model/game.store'
import type { GameWS } from '../model/game.types'

import { useGameUser } from './useGameUser'

export function useGameControls() {
	const { ws, game } = useGameStore()
	if (!ws) throw new Error('GameControls :: Game WebSocket is null')

	const player = useGameUser()
	if (!player) throw new Error('GameControls :: Player is not at table')

	const limits = {
		max: player.player_balance,
		min:
			player.player_balance > game.blind_amount * 2 ? game.blind_amount * 2 : player.player_balance
	}

	const onCheck = async () => {
		await ws.send<GameWS['action']>({ type: 'action', action: 'check' })
	}

	const onCall = async () => {
		await ws.send<GameWS['action']>({ type: 'action', action: 'call' })
	}

	const onRaise = async (amount: number) => {
		await ws.send<GameWS['action']>({ type: 'action', action: 'raise', amount })
	}

	const onFold = async () => {
		await ws.send<GameWS['action']>({ type: 'action', action: 'fold' })
	}

	const handleRaise = () => {
		if (raise === 0) setRaise(limits.min)
		else {
			onRaise(raise)
			setRaise(0)
		}
	}

	const [raise, setRaise] = useState(0)
	const onChangeRaise = (value: number) => setRaise(value)

	const onSetPot = () => setRaise(game.pot)
	const onSetHalfPot = () => setRaise(Math.floor(game.pot / 2))
	const onAllIn = () => setRaise(player.player_balance)

	return {
		onFold,
		onCheck,
		onCall,
		handleRaise,

		onSetHalfPot,
		onSetPot,
		onAllIn,
		onChangeRaise,

		raise,
		limits
	}
}
