import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button, Input } from '@/shared/ui'
import { formatNumber } from '@/shared/utils'

import { useGameControls } from '../../hooks/useGameControls'

import c from './GameControls.module.scss'

export function GameControls() {
	const { t } = useTranslation()
	const {
		onFold,
		onCheck,
		onCall,
		handleRaise,
		onSetHalfPot,
		onSetPot,
		onAllIn,
		onChangeRaise,
		raise,
		limits
	} = useGameControls()

	const raiseText = raise ? ` - ${formatNumber(raise)}$` : ''

	return (
		<div className={c['game-controls']}>
			<div className={cn(c['game-controls__raise'], raise && c['--visible'])}>
				<Button size='sm' color='yellow' text={t('game_action-1/2-pot')} onClick={onSetHalfPot} />
				<Button size='sm' color='yellow' text={t('game_action-pot')} onClick={onSetPot} />
				<Button size='sm' color='yellow' text={t('game_action-all-in')} onClick={onAllIn} />
				<Input.Range
					className={c['game-controls__raise-range']}
					max={limits.max}
					onChange={onChangeRaise}
					value={raise}
					min={limits.min}
				/>
			</div>

			<div className={c['game-controls__action']}>
				<Button onClick={onFold} text={t('game_action-fold')} />
				<Button onClick={onCheck} text={t('game_action-check')} />
				<Button onClick={onCall} text={t('game_action-call')} />
				<Button onClick={handleRaise} text={t('game_action-raise', { raise: raiseText })} />
			</div>
		</div>
	)
}
