import { WebSocketManager } from '@/shared/utils'

import { gameDynamicsInit } from './game.init'
import { gameStoreManager } from './game.store'
import type { GameWS } from './game.types'

export const gameCreateWS = () => {
	gameStoreManager(s => {
		s.ws = new WebSocketManager('/game/1/')

		s.ws.connect()
	})
}

export const gameRemoveWS = () => {
	gameStoreManager(s => {
		if (s.ws) {
			s.ws.close()
			s.ws = null
			s.playerSeat = null
			s.game = { ...gameDynamicsInit }
		} else console.error(`Game websocket in null`)
	})
}

export const gameChangeState = ({ type, ...data }: GameWS['game_state']) => {
	void type
	gameStoreManager(s => {
		s.game = { ...data }
	})
}

export const gameSetPlayerSeat = (seatIndex: number) => {
	gameStoreManager(s => {
		s.playerSeat = seatIndex
	})
}
