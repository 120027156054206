import { API_WS_URL } from '@/app/configs/envs'

import type { MsgType, WSMsg } from '../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventHandler<T = any> = (...args: T[]) => void
export class WebSocketManager {
	private socket: WebSocket | null = null
	readonly url: string
	readonly query: string | null = null
	private eventHandlers: Map<string, EventHandler[]> = new Map()

	constructor(url: string, query?: string | Record<string, unknown>) {
		this.url = url

		if (typeof query === 'string') {
			this.query = query
		} else if (typeof query === 'object') {
			const queryString = new URLSearchParams(
				Object.entries(query).map(([key, value]) => [key, String(value)])
			).toString()
			this.query = queryString
		} else if (typeof query === 'undefined') {
			return
		} else {
			throw new Error('Invalid query provided')
		}
	}

	// Метод для подключения WebSocket с возможностью переподключения
	public connect(): void {
		if (this.socket && this.socket.readyState === WebSocket.OPEN) {
			console.warn('WebSocket is already connected')
			return
		}

		const baseUrl = `${API_WS_URL}${this.url}`
		const wsUrl = this.query ? `${baseUrl}?${this.query}` : baseUrl

		this.socket = new WebSocket(wsUrl)

		this.socket.onopen = () => {
			console.log('WebSocket connection opened')
			this.emit('open')
		}

		this.socket.onmessage = (event: MessageEvent<string>) => {
			const data: MessageEvent<WSMsg> = JSON.parse(event.data)
			console.log('Received message:', data)
			this.emit(data.type, data)
		}

		this.socket.onerror = error => {
			console.error('WebSocket error:', error)
			this.emit('error', error)
		}

		this.socket.onclose = event => {
			console.log('WebSocket connection closed', event)
			this.emit('close', event)
		}
	}

	// Метод для отправки сообщений с проверкой состояния соединения
	public async send<T extends MsgType>(message: WSMsg<T>) {
		if (this.socket?.readyState === WebSocket.OPEN) {
			this.socket.send(JSON.stringify(message))
		} else {
			console.warn('WebSocket is not connected')
		}
	}

	// Метод для закрытия WebSocket соединения
	public close(): void {
		if (this.socket) {
			this.socket.close()
		}
	}

	// Подписка на события WebSocket
	public on<Data>(event: string, handler: EventHandler<Data>): void {
		if (!this.eventHandlers.has(event)) {
			this.eventHandlers.set(event, [])
		}
		this.eventHandlers.get(event)?.push(handler)
	}

	// Отписка от событий WebSocket
	public off(event: string, handler: EventHandler): void {
		const handlers = this.eventHandlers.get(event)
		if (handlers) {
			this.eventHandlers.set(
				event,
				handlers.filter(h => h !== handler)
			)
		}
	}

	// Вызов обработчиков событий
	private emit(event: string, ...args: unknown[]): void {
		const handlers = this.eventHandlers.get(event)
		handlers?.forEach(handler => handler(...args))
	}
}
