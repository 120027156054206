import { useEffect, useMemo } from 'react'

import { useCreateOverlay, useOverlay } from '@/components/Overlay'

import { rotateSeats, useGameActions, useGameStore } from '@/entities/game'

export function useGameContent() {
	const { ws, playerSeat } = useGameStore()
	if (!ws) throw new Error('Game WebSocket is null at GameContent')

	const { game } = useGameStore()

	const chairs = useMemo(() => {
		if (playerSeat === null) return rotateSeats(game.chairs, 4)
		else return rotateSeats(game.chairs, playerSeat)
	}, [game.chairs, playerSeat])

	const { gameChangeState } = useGameActions()

	useCreateOverlay('buy-in-overlay')
	const [isOverlayVisible] = useOverlay('buy-in-overlay')

	useEffect(() => {
		ws.on('game_state', gameChangeState)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return { chairs, isOverlayVisible }
}
