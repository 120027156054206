import classNames from 'classnames'
import { useState } from 'react'

import c from './InputRadio.module.scss'

interface TRadio {
	checked?: boolean
	onChange?(checked: boolean): void

	label?: string | React.ReactNode

	className?: string
}

export function InputRadio({ checked = false, onChange, label, className }: InputRadioProps) {
	const [isChecked, setChecked] = useState<boolean>(checked)

	const toggleCheck = () => {
		const newChecked = !isChecked
		setChecked(newChecked)

		if (onChange) onChange(newChecked)
	}

	return (
		<div className={c['input-radio-wrapper']} onClick={toggleCheck}>
			<input
				type='radio'
				checked={isChecked}
				onChange={toggleCheck}
				aria-checked={isChecked}
				hidden
			/>
			<div
				className={classNames(c['input-radio'], isChecked && c['checked'], className)}
				aria-checked={isChecked}>
				<div className={c['input-radio__dot']} />
			</div>
			{label && <span className={c['input-radio__label']}>{label}</span>}
		</div>
	)
}

type InputRadioProps = TRadio & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof TRadio>
