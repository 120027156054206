import classNames from 'classnames'

import c from './Button.module.scss'

type BtnProps = {
	color?: 'gold' | 'green' | 'orange' | 'white' | 'yellow' | 'red'
	size?: 'sm' | 'md' | 'lg'
	type?: 'primary' | 'primary-round' | 'ghost'
	text?: string
}

export function Button({
	type = 'primary',
	color = 'green',
	size = 'md',
	text = 'Button',
	children,
	className,
	...btnProps
}: ButtonProps) {
	const btnClassNames = classNames(c['btn'], c[type], c[size], c[color], className)
	return (
		<button className={btnClassNames} {...btnProps}>
			{children ?? <span>{text}</span>}
		</button>
	)
}

type ButtonProps = BtnProps & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BtnProps>
