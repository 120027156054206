import { userAPI } from '../api/user.api'

import { userStoreManager } from './user.store'
import { TUser } from './user.types'

export function userSetData(data: Partial<TUser>) {
	userStoreManager(s => ({ ...s, ...data }))
}

export async function userAuth() {
	await userAPI.getTokens({ tg_data: window.Telegram.WebApp.initData })
}

export async function userGetMe() {
	const data = await userAPI.getMe()
	userStoreManager(() => ({ ...data }))
}
