import c from './Chip.module.scss'

interface ChipProps {
	w?: string
	innerText?: string
	label?: string
}

export function Chip({ innerText, label, w = '1.25rem' }: ChipProps) {
	return (
		<div className={c['chip-wrapper']} style={{ width: w, height: w }}>
			{label && <div className={c['chip-label']}>{label}</div>}
			<div className={c.chip}>{innerText && <span>{innerText}</span>}</div>
		</div>
	)
}
