import { useLayoutEffect } from 'react'

import { withLayout } from '@/app/layouts/withLayout'

import { Chat } from '@/components/Chat'
import { GameContent, GameFooter, GameHeader } from '@/components/Game'

import { useGameActions } from '@/entities/game'

import c from './GamePage.module.scss'

export function GamePage() {
	const { gameCreateWS, gameRemoveWS } = useGameActions()

	// Создаем и подключаемся к игровому сокету при загрузке страницы
	gameCreateWS()

	useLayoutEffect(() => {
		// Отключаемся и отчищаем его при отгрузке.
		return () => gameRemoveWS()
	}, [gameRemoveWS])

	return (
		<div className={c['page-game']}>
			<Chat />
			<GameHeader />
			<GameContent />
			<GameFooter />
		</div>
	)
}

const GamePageWithLayout = withLayout(GamePage)
export default GamePageWithLayout
