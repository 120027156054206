import { BuyIn } from '@/components/BuyIn'
import { Overlay } from '@/components/Overlay'

import { GameSeat, GameTable } from '@/entities/game'
import { UserInGame } from '@/entities/user'

import { useGameContent } from '../../hooks/useGameContent'

import c from './GameContent.module.scss'

export function GameContent() {
	const { chairs, isOverlayVisible } = useGameContent()

	return (
		<div className={c['game-content']}>
			<GameTable />
			{chairs.map(({ item, index }, i) =>
				item ? (
					<UserInGame key={`player-seat-${index}`} index={index} seat={i + 1} {...item} />
				) : (
					<GameSeat key={`game-seat-${index}`} index={index} seat={i + 1} />
				)
			)}

			{isOverlayVisible && <Overlay children={<BuyIn />} />}
		</div>
	)
}
