import cn from 'classnames'

import { useGetCardSprite } from '@/entities/files'

import { Card } from '../../model/game.types'

import c from './PlayerCard.module.scss'

export type PlayerCardProps = {
	isFlipped?: boolean
	isPlayer?: boolean
} & Card

export function PlayerCard({ codeName, isFlipped, isPlayer = false }: PlayerCardProps) {
	const spriteURL = useGetCardSprite(codeName)

	return (
		<div className={cn(c['player-card'], isFlipped && c['flipped'], isPlayer && c['player'])}>
			<img width={63} height={79} src={spriteURL} />
		</div>
	)
}
