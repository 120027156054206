import { useTranslation } from 'react-i18next'

import { Button, Input } from '@/shared/ui'

import { useBuyInContent } from '../../hooks/useBuyInContent'
import { betText } from '../../lib/buyIn.constants'

import c from './BuyInContent.module.scss'

export function BuyInContent() {
	const { t } = useTranslation()
	const {
		buyInW,
		limits,
		step,
		buyInAmmount,
		onChangeBuyInAmmount,
		setMaxBuyInAmmount,
		setMinBuyInAmmount,
		onBuyIn
	} = useBuyInContent()

	const controlsBtnProps = {
		className: c['selected-buy-in--limit-btn'],
		color: 'yellow',
		size: 'sm'
	} as const

	return (
		<div className={c['buy-in-content']}>
			<span className={c['buy-in-content__bet']}>{t('bet-with-money', { betText })}</span>

			<div className={c['buy-in-content__controls']}>
				<div className={c['buy-in-content__controls--header']}>
					{/* Min Button */}
					<div className={c['selected-buy-in--limit']}>
						<Button onClick={setMinBuyInAmmount} text={t('min')} {...controlsBtnProps} />
						<span>{limits.min}$</span>
					</div>

					{/* Selected buy-in */}
					<div className={c['selected-buy-in']}>
						<h3>{t('buy-in_selected-buy-in-label')}</h3>
						<div className={c['selected-buy-in__ammount']} style={{ width: buyInW }}>
							<span>{buyInAmmount}$</span>
						</div>
					</div>

					{/* Max Button */}
					<div className={c['selected-buy-in--limit']}>
						<Button onClick={setMaxBuyInAmmount} text={t('max')} {...controlsBtnProps} />
						<span>{limits.max}$</span>
					</div>
				</div>

				<Input.Range
					min={limits.min}
					max={limits.max}
					step={step}
					value={buyInAmmount}
					onChange={onChangeBuyInAmmount}
				/>
			</div>

			<div className={c['buy-in-content__submit']}>
				<Input.Radio label={t('buy-in_auto-replenishment-text')} />
				<Button text={t('buy-in_submit-btn-text')} color='gold' onClick={onBuyIn} />
			</div>
		</div>
	)
}
