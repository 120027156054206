import { useState } from 'react'

import { useOverlay } from '@/components/Overlay'

import { GameWS, useGameStore } from '@/entities/game'

import { buyInConf } from '../lib/buyIn.constants'
import { getSelectedBuyInAddW } from '../lib/buyIn.helpers'

export function useBuyInContent() {
	const ws = useGameStore(s => s.ws)
	if (!ws) throw new Error('BuyInContent :: Game Websocket is null')

	const [, toggleBuyInOverlay] = useOverlay('buy-in-overlay')
	const onBuyIn = async () => {
		await ws.send<GameWS['ready']>({ type: 'ready', buy_in: buyInAmmount })
		toggleBuyInOverlay(false)
	}

	// TODO: replace with data from backend
	const { init, limits, step } = buyInConf

	const [buyInAmmount, setBuyInAmmount] = useState<number>(init)

	const onChangeBuyInAmmount = (value: number) => setBuyInAmmount(value)
	const setMinBuyInAmmount = () => setBuyInAmmount(limits.min)
	const setMaxBuyInAmmount = () => setBuyInAmmount(limits.max)

	const buyInW = `calc(5.375rem + ${getSelectedBuyInAddW(limits.max)}rem)`

	return {
		onBuyIn,
		limits,
		step,
		buyInAmmount,
		onChangeBuyInAmmount,
		setMaxBuyInAmmount,
		setMinBuyInAmmount,
		buyInW
	}
}
